import { QueryHookOptions, gql } from '@apollo/client'

import { ResolutionTypes } from '../../contexts/setup'
import { ResolutionType } from '../../types/tibber'

type ConsumptionQueryOptions = QueryHookOptions & {
  after?: string
  before?: string
  first?: number
  homeId?: string | null | false
  last?: number
  resolution?: ResolutionType
}
export const getConsumptionQueryOptions = ({
  after,
  before,
  first,
  homeId = window.localStorage.getItem('homeId'),
  last: givenLast,
  resolution = ResolutionTypes[0] as ResolutionType
}: ConsumptionQueryOptions) => {
  let last: number | undefined =
    (givenLast || parseInt(window.localStorage.getItem('unit') || '', 10)) ?? 48
  if (first) {
    last = undefined
  }

  const variables = {
    resolution,
    homeId,
    before,
    after,
    last,
    first
  }

  return {
    variables,
    skip: !homeId
  }
}
export const ConsumptionQuery = gql`
  query getConsumptionData(
    $resolution: EnergyResolution!
    $last: Int
    $before: String
    $first: Int
    $after: String
    $homeId: ID!
  ) {
    viewer {
      userId
      home(id: $homeId) {
        id
        mainFuseSize
        features {
          realTimeConsumptionEnabled
        }
        consumption(
          resolution: $resolution
          last: $last
          before: $before
          first: $first
          after: $after
        ) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          nodes {
            from
            to
            unitPrice
            unitPriceVAT
            cost
            currency
            consumption
            consumptionUnit
          }
        }
      }
    }
  }
`
