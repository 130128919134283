import React from "react"
import "./index.css"

import { ApolloProvider } from "@apollo/client"
import ReactDOM from "react-dom/client"

import { client } from "./ApolloClient"
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
)
serviceWorkerRegistration.register()
