export function useMessage () {
  type NotificationType = {
    title?: string
    options?: NotificationOptions | undefined
    callback?: any
  }
  async function sendNotification ({
    title,
    options,
    callback
  }: NotificationType) {
    const dontSpeak = !window.Notification || title === undefined
    if (dontSpeak) return false

    if (Notification.permission === 'denied') {
      return false
    } else if (Notification.permission === 'default') {
      Notification.requestPermission().then(perm => {
        if (perm === 'granted') {
          return sendNotification({ title, options, callback })
        }
      })
      return false
    }
    console.log({ options })
    const notification = new Notification(title || 'Notification', {
      icon: '/icon.svg',
      ...options
    })
    if (callback !== undefined) {
      notification.onclick = function () {
        callback()
        notification.close()
      }
    }
  }

  return [sendNotification]
}
