import React from 'react'

import dayjs from 'dayjs'

import { fCur, getTodayWithHours } from '../../helpers/intlFormats'
import { useMessage } from '../../hooks/notificationHook'
import { TIME } from '../../hooks/timeChange'
import { PriceType, PriceUpcomingResponseType } from '../../types/tibber'

const d = dayjs().minute(0)
export const now = getTodayWithHours({ date: d.toString() })
export const LS_KEY = 'priceUpcoming'

// using `day` instead of `date` makes us store 7 days of data instead of full month
export const STORAGE = {
  CLEARINGDAY: `${LS_KEY}.${d.subtract(2, 'days').day()}`,
  YESTERDAY: `${LS_KEY}.${d.subtract(1, 'days').day()}`,
  TODAY: `${LS_KEY}.${d.day()}`,
  TOMORROW: `${LS_KEY}.${d.add(1, 'days').day()}`
}

export const getFromStorage = (key: string): PriceType[] | null => {
  const data = window.localStorage.getItem(key)
  return data ? JSON.parse(data) : null
}

export const setInStorage = (key: string, data: object): void =>
  window.localStorage.setItem(key, JSON.stringify(data))

export const getPollIntervall = (hour: number) => {
  const cachedTomorrow = getFromStorage(STORAGE.TOMORROW)
  if (hour >= 12 && !cachedTomorrow?.length) {
    return 1 * TIME.minute
  }
  return TIME.minute * 3
}

export const parser = (i: PriceType, index: number): PriceType => {
  const clear = getFromStorage(STORAGE.CLEARINGDAY)
  if (clear) {
    window.localStorage.removeItem(STORAGE.CLEARINGDAY)
  }
  const end = new Date(i.startsAt)
  end.setMinutes(59)
  return {
    ...i,
    todayTotal: index < 25 ? i.total : undefined,
    tomorrowTotal: index > 23 ? i.total : undefined,
    totalFormatted: (i.total && fCur(i.total)) || undefined,
    label: getTodayWithHours({ date: i.startsAt })
  }
}

const fakeDate = new Date(new Date().setMinutes(0))
fakeDate.setDate(fakeDate.getDate() + 1)
const dummyDay = [...Array(24)].map(
  (i, index): PriceType => ({
    startsAt: new Date(fakeDate.setHours(index)).toISOString(),
    currency: 'SEK'
  })
)

const [sendNotification] = useMessage()

export const getPrices = (
  setPrices: React.Dispatch<React.SetStateAction<PriceType[]>>,
  data?: PriceUpcomingResponseType
): void => {
  const cachedYesterday = getFromStorage(STORAGE.YESTERDAY)
  const cachedToday = getFromStorage(STORAGE.TODAY)
  const cachedTomorrow = getFromStorage(STORAGE.TOMORROW)
  const { today, tomorrow } =
    data?.viewer.home.currentSubscription.priceInfo || {}
  if (today?.length) setInStorage(STORAGE.TODAY, today)
  if (tomorrow?.length) {
    setInStorage(STORAGE.TOMORROW, tomorrow)
    sendNotification({
      title: "We've got tomorrows Prices",
      options: {
        body: 'The prices for tomorrow has arrived',
        vibrate: [200, 100, 200]
      }
    })
  }

  const hasTomorrowData = !!cachedTomorrow?.length

  const tm: PriceType[] = hasTomorrowData
    ? tomorrow || cachedTomorrow
    : cachedToday ?? dummyDay
  const td: PriceType[] = hasTomorrowData
    ? cachedToday || today || dummyDay
    : cachedYesterday || dummyDay
  setPrices([td, tm].flat())
}
