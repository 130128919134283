import React, { createContext, useEffect, useState } from "react"

import { useQuery } from "@apollo/client"

import "./App.module.scss"
import styles from "./App.module.scss"
import LoadingIndicator from "./components/LoadingIndicator/LoadingIndicator"
import Settings from "./components/Settings/Settings"
import { ColorContext, Themes, getColorContext } from "./contexts/ColorContext"
import { InitialQuery } from "./graphql/queries/getInitialData"
import Calendar from "./pages/Calendar/Calendar"
import CostHistory from "./pages/CostHistory/CostHistory"
import Header from "./pages/Header/Header"
import PriceUpcoming from "./pages/PriceUpcoming/PriceUpcoming"
import { InitialResponseType } from "./types/tibber"

const { REACT_APP_MAINTENANCE_MODE: MAINTENANCE_MODE } = process.env
const isMaintenanceMode = MAINTENANCE_MODE === "true"

const App = () => {
  const themeListener = window.matchMedia("(prefers-color-scheme: dark)")
  const [access, setAccess] = useState<string | null>(null)
  const [themeOverride, setThemeOverride] = useState<null | Themes>(null)
  const getTheme = (match?: boolean) => {
    const m = match !== undefined ? match : themeListener.matches
    return m ? Themes.DARK : Themes.LIGHT
  }
  const [theme, setTheme] = useState(getTheme())

  const updateTheme = (e: MediaQueryListEvent) => {
    setTheme(getTheme(e.matches))
  }
  const test = async () => {
    const { authorization_code } =
      !access &&
      (await fetch("http://localhost:8080")
        .then((response) => response.json())
        .then(({ data }) => data))
    setAccess(authorization_code)
  }

  // useEffect(() => {
  //   test()
  // }, [])

  const {
    loading,
    error,
    data: loadedData,
  } = useQuery<InitialResponseType, any>(InitialQuery, {
    skip: isMaintenanceMode,
  })
  if (error) {
    return <p>Error :(</p>
  }

  useEffect(() => {
    if (!themeOverride) {
      themeListener.addEventListener("change", updateTheme)
    }
    return () => {
      themeListener.removeEventListener("change", updateTheme)
    }
  }, [themeOverride])

  if (!loading && loadedData && !window.localStorage.getItem("homeId")) {
    window.localStorage.setItem("homeId", loadedData.viewer.homes[0].id)
  }
  const colorCtx = getColorContext(themeOverride || theme)

  return (
    <ColorContext.Provider value={colorCtx}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <main className={styles.App}>
          {isMaintenanceMode ? (
            <>
              <h1>Tibber</h1>
              <img src="/icon.svg" className={styles.maintenanceLogo} />
              <p>We&apos;re currently down for maintenance.</p>
            </>
          ) : (
            <>
              <Settings theme={themeOverride} setTheme={setThemeOverride} />
              <Header />
              <PriceUpcoming />
              <CostHistory />
              {/* <Calendar /> */}
            </>
          )}
        </main>
      )}
    </ColorContext.Provider>
  )
}

export default App
