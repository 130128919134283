export const fCur = (n: number): string =>
  Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(n)
export const fCurKwh = (n: number): string => `${fCur(n)}/kWh`
export const fKwh = (n: number): string =>
  `${Intl.NumberFormat('sv-SE', {
    maximumSignificantDigits: 3
  }).format(n)} kWh`

type FormatDateType = {
  date: string
  locale?: string
  options?: Intl.DateTimeFormatOptions
}

const defaultFormatOpts: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  month: 'long',
  day: 'numeric'
}
export const formatDate = ({
  date,
  locale = 'sv',
  options = defaultFormatOpts
}: FormatDateType) =>
  new Intl.DateTimeFormat(locale, options).format(new Date(date))

export const getToday = (
  locale = 'sv',
  opts: Intl.DateTimeFormatOptions = { dateStyle: 'short' }
) => new Intl.DateTimeFormat(locale, opts).format(new Date())

export const getTomorrow = (
  locale = 'sv',
  opts: Intl.DateTimeFormatOptions = { dateStyle: 'short' }
): string => {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  return new Intl.DateTimeFormat(locale, opts).format(tomorrow)
}

type GetTodayWithHoursProps = {
  locale?: string
  date: string
}
export const getTodayWithHours = ({
  locale = 'sv',
  date
}: GetTodayWithHoursProps): string =>
  new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(new Date(date))

export const isToday = (someDate: Date): boolean => {
  const today = new Date()
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  )
}
