import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist'

const httpLink = createHttpLink({
  uri: 'https://api.tibber.com/v1-beta/gql'
})

const {
  REACT_APP_MAINTENANCE_MODE: MAINTENANCE_MODE,
  REACT_APP_API_TOKEN: API_TOKEN
} = process.env

const token = MAINTENANCE_MODE === 'true' ? null : API_TOKEN
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    Viewer: {
      keyFields: ['userId']
    }
  }
})

persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage)
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache
})
