import React, { useContext, useEffect, useState } from "react"

import { linearGradientDef } from "@nivo/core"
import { Line } from "@nivo/line"

import { ColorContext } from "../../contexts/ColorContext"
import { commonProps } from "../../helpers/chartHelpers"
import { getLineData } from "../../helpers/parseTibberData"
import { useTimeChanges } from "../../hooks/timeChange"
import { EnergyItem } from "../../types/tibber"
import PriceTick from "../Ticks/PriceTick"
import TooltipSlice from "../Tooltip/TooltipSlice"

type MainChartProps = {
  consumption: EnergyItem[]
}

const MainChart = ({ consumption }: MainChartProps) => {
  const { day } = useTimeChanges()
  const colors = useContext(ColorContext)
  const lineData = getLineData(consumption, colors)
  const lineProps = {
    ...commonProps(colors),
    indexBy: "label",
    data: lineData,
    enableArea: true,
    areaOpacity: 1,
    lineWidth: 1,
    pointSize: 3,
    useMesh: true,
  }

  return (
    <div style={{ position: "relative" }}>
      <Line
        xScale={{ type: "point" }}
        enableSlices="x"
        sliceTooltip={TooltipSlice}
        axisLeft={{
          renderTick: (tick) => PriceTick(tick),
        }}
        axisBottom={{
          tickRotation: -45,
        }}
        curve="basis"
        {...lineProps}
        defs={[
          linearGradientDef("area", [
            { offset: 0, color: "inherit", opacity: 0.4 },
            { offset: 100, color: "inherit", opacity: 0.1 },
          ]),
        ]}
        fill={[{ match: "*", id: "area" }]}
        colors={({ color }) => color}
      />
      <p>Updated on the {day}</p>
    </div>
  )
}

export default MainChart
