import { ResolutionType } from '../types/tibber'

type EnergyResolutions = {
  HOURLY: string
  DAILY: string
  WEEKLY: string
  MONTHLY: string
  ANNUAL: string
}

export const ResolutionTypes: ResolutionType[] = [
  'HOURLY',
  'DAILY',
  'WEEKLY',
  'MONTHLY',
  'ANNUAL'
]

const energyResolutions: EnergyResolutions = {
  HOURLY: 'hours',
  DAILY: 'days',
  WEEKLY: 'weeks',
  MONTHLY: 'months',
  ANNUAL: 'years'
}

export type DictType = {
  energyResolutions: EnergyResolutions
}
const dict: DictType = { energyResolutions }

export default dict
