import { createContext } from 'react'

import colors from '../styles/colors.module.scss'

export enum Themes {
  DARK = '0',
  LIGHT = '1'
}
export type ColorContextType = {
  colorWhite: string
  colorText: string
  colorGray: string
  colorYellow: string
  colorGreen: string
  colorBlue: string
  colorGridLine: string
  colorGridTick: string
  colorGridEmpty: string
  colorBackground: string
  colorBackgroundLight: string
  colorPrice: string
  colorConsumption: string
  colorCost: string
  colorSpinner: string
}
export const ColorContext = createContext<ColorContextType>({
  colorWhite: '',
  colorText: '',
  colorGray: '',
  colorYellow: '',
  colorGreen: '',
  colorBlue: '',
  colorGridLine: '',
  colorGridTick: '',
  colorGridEmpty: '',
  colorBackground: '',
  colorBackgroundLight: '',
  colorPrice: '',
  colorConsumption: '',
  colorCost: '',
  colorSpinner: ''
})

export const getColorContext = (theme: Themes): ColorContextType => {
  const obj: any = {}
  Object.keys(colors).forEach(k => {
    const currentTheme = theme
    if (k.endsWith(currentTheme)) {
      const cleanK = k.replace(currentTheme, '')
      const kName = '--' + cleanK.replace(/[A-Z]/g, m => '-' + m.toLowerCase())
      const value = colors[k]
      obj[cleanK] = value
      document.documentElement.style.setProperty(kName, value)
    }
  })
  return obj as ColorContextType
}
