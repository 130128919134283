import { useState, useEffect } from 'react'

type TimeType = {
  second: number
  minute: number
  hour: number
  day: number
}
export const TIME: TimeType = (() => {
  const second = 1000
  const minute = second * 60
  const hour = minute * 60
  return {
    second,
    minute,
    hour,
    day: hour * 24
  }
})()

export function useTimeChanges () {
  const currentDate = new Date()
  const [hour, setHour] = useState(currentDate.getHours())
  const [day, setDay] = useState(currentDate.getDate())
  const [minute, setMinute] = useState(currentDate.getMinutes())

  useEffect(() => {
    const interval = setInterval(() => {
      const compareDate = new Date()
      if (compareDate.getHours() !== hour) setHour(compareDate.getHours())
      if (compareDate.getDate() !== day) setDay(compareDate.getDate())
      if (compareDate.getMinutes() !== minute)
        setMinute(compareDate.getMinutes())
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [hour, day, minute])

  return { hour, day, currentDate }
}
