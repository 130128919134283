import { gql } from '@apollo/client'

import { getToday, getTomorrow } from '../../helpers/intlFormats'

type PriceUpcomingOptions = {
  homeId?: string | null | false
  hasToday?: boolean
  hasTomorrow?: boolean
}
export const PriceUpcomingQueryOptions = ({
  homeId = window.localStorage.getItem('homeId'),
  hasToday = Boolean(
    window.localStorage.getItem(`prices.hourly.${getToday()}`)
  ),
  hasTomorrow = Boolean(
    window.localStorage.getItem(`prices.hourly.${getTomorrow()}`)
  )
}: PriceUpcomingOptions) => {
  const variables = {
    homeId
  }

  const skip =
    !homeId ||
    (hasTomorrow && hasToday) ||
    (hasToday && new Date().getHours() < 12)

  return {
    variables,
    skip
  }
}
export const PriceUpcomingQuery = gql`
  query getPriceUpcomingData($homeId: ID!) {
    viewer {
      userId
      home(id: $homeId) {
        id
        currentSubscription {
          id
          priceInfo {
            today {
              total
              startsAt
              currency
            }
            tomorrow {
              total
              startsAt
              currency
            }
          }
        }
      }
    }
  }
`
