import React, { useState } from "react"

import { useQuery } from "@apollo/client"

import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator"
import PeriodStats from "../../components/PeriodStats/PeriodStats"
import HomeSwitcher from "../../components/Switchers/HomeSwitcher"
import ResolutionSwitcher from "../../components/Switchers/ResolutionSwitcher"
import MainChart from "../../components/Tibber/MainChart"
import { ResolutionTypes } from "../../contexts/setup"
import {
  ConsumptionQuery,
  getConsumptionQueryOptions,
} from "../../graphql/queries/getConsumption"
import { ConsumptionResponseType, ResolutionType } from "../../types/tibber"
import styles from "./Header.module.scss"

const initialResolution =
  window.localStorage.getItem("resolution") || ResolutionTypes[0]

const Header = () => {
  const [resolution, setResolution] = useState<ResolutionType>(
    initialResolution as ResolutionType
  )
  const [units, setUnits] = useState<number>(new Date().getHours() + 24)
  const [homeId, setHomeId] = useState<string>(
    window.localStorage.getItem("homeId") as string
  )

  const { loading, error, data } = useQuery<ConsumptionResponseType>(
    ConsumptionQuery,
    getConsumptionQueryOptions({
      homeId,
      last: units,
      resolution,
    })
  )

  if (error) {
    console.error({ error })
  }

  const consumption = data?.viewer.home.consumption.nodes
  let cache = window.localStorage.getItem("home.consumption")

  if (!loading && cache !== JSON.stringify(consumption)) {
    cache = JSON.stringify(consumption)
    window.localStorage.setItem("home.consumption", cache)
  }
  return (
    <header className={styles.AppHeader} style={{ position: "relative" }}>
      {cache && <PeriodStats consumption={JSON.parse(cache)} />}
      <h1 className={styles.title}>
        <HomeSwitcher setHomeId={setHomeId} />
      </h1>
      <ResolutionSwitcher
        resolution={resolution}
        setResolution={setResolution}
        units={units}
        setUnits={setUnits}
      />
      {cache && <MainChart consumption={JSON.parse(cache)} />}
      {loading && <LoadingIndicator />}
    </header>
  )
}

export default Header
