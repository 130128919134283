import { gql } from '@apollo/client'

export const InitialQuery = gql`
  query getInitialData {
    viewer {
      userId
      login
      name
      accountType
      homes {
        id
        appNickname
        mainFuseSize
        features {
          realTimeConsumptionEnabled
        }
      }
    }
  }
`
